.get-in-touch{
    padding: 2rem
}

.get-in-touch .subtitle{
    color: black !important;
}

.get-in-touch .text{
    text-align: initial;
}

.get-in-touch .d-flex-center {
    gap: 5rem;
}

@media (max-width: 768px) {
    .d-flex-center{
        flex-direction: column !important;
    }

    .get-in-touch .contact-us{
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 0;
    }
    
    .get-in-touch .d-flex-center{
        gap: 0;
    }
}