.footer{
    
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(50,99,185,1) 90%) !important;
    margin-bottom: 0;
    

}
.footer .column {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
}

.footer h6{
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
}

.footer a{
    color: white;
}

.footer a:hover{
    color: white;
    background-color: transparent;
}

.footer b{
    color: white;
}


.footer p{
    color: white;
}

.footer-third{
    margin: 0 !important;
    justify-content: flex-start !important;
}