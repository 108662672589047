.button-creative{
  width: fit-content;
  
}

.navbar .button-creative{
  margin: 0.5em;
}

.get-in-touch .button-creative{
  margin: 0.5em 0;
}

.choose-your-plan .button-creative,
.discover-our-services .button-creative {
  margin: auto;
}

.button-creative ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

}

.button-creative ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 50px;
  font-weight: 600;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;

  padding: 0.5rem 1rem;
}

.button-primary-creative ul li {
  
  --c: #3263B9;
    color: #ffffff;
    border: 0.2em solid #3263B9;
    background: #3263B9;
}

.button-secondary-creative ul li {
  --c: #4F5665;
  color: var(--c);
  border: 0.1em solid var(--c);
}

.button-creative ul li span {
  position: absolute;
  width: 25%;
  height: 100%;
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.button-primary-creative ul li span {
  background-color: var(--c);
}

.button-secondary-creative ul li span {
  background-color: var(--c);
}


.button-primary-creative ul li a {
  color: #3263B9;
}

.button-secondary-creative ul li a {
  color: #4F5665;
}

.button-creative ul li:hover {
  color: rgb(255, 255, 255);
}

.button-creative ul li:hover span {
  transform: translateY(0) scale(2);
}

.button-creative ul li span:nth-child(1) {
  --n: 1;
}

.button-creative ul li span:nth-child(2) {
  --n: 2;
}

.button-creative ul li span:nth-child(3) {
  --n: 3;
}

.button-creative ul li span:nth-child(4) {
  --n: 4;
}
