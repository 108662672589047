.characteristics .subtitle {
    text-align: initial;
}

.characteristics p {
    text-align: initial;
}

.characteristics .subsubtitle1 {
    margin: 1.5rem 0;
}


@media (max-width: 768px) {

    .characteristics .subtitle {
        text-align: center;
    }
    
    .characteristics p {
        text-align: center;
    }

    .characteristics .columns .column:nth-child(1) {
        order: 2;
    }

    .characteristics .columns .column:nth-child(1) {
        order: 1;
    }

    .characteristics .columns {
        display: flex;
        flex-direction: column;
    }
}