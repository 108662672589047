body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Lato', sans-serif;
}

.button.is-primary {
  background-color: #3263B9 !important;
  font-weight: 600;
  border-radius: 1rem;
}

.button.is-primary a {
  color: white;
}

.subtitle {
  color: #3263B9 !important;
  font-weight: 800 !important;
  font-size: 2rem !important;
  margin-bottom: 0.6rem !important;
}

.subsubtitle1 {
  font-weight: 600;
}

.subsubtitle2 {
  font-weight: 400;
  font-size: 1.1rem;
}

.subsubtitle2,
.subsubtitle1,
p {
  color: #4F5665;
}

section {
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.background-gradient-blue {
  background-image: url('./assets/images/backCertificados.png');
  background-size: cover;
}

.d-flex{
  display: flex;
}

.d-flex-column{
  display: flex;
  flex-direction: column;
}

.d-flex-center{
  display: flex;
  align-items: center;
}

.d-flex-centrado {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.direction-row {
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.flex-same-size{
  flex: 1 1 0px;
}

.background-grey-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, #C3CBCD 0%, #DFE7E9 100%);
}

.background-grey{
  background: #F3F1F1;
}

.bullet img{
  margin: 0 0.8rem 0 0;
}

.bullet p{
  margin: 1rem 0;
}

section.get-in-touch,
section#contactUs{
  margin-bottom: 0rem;
}

@media (max-width: 1024px) {
  section{
    padding: 0 1rem;
  }

  .columns {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    margin-top: 0rem !important;
  }

  .columns:last-child {
    margin-bottom: 0 !important;
  }

  .container{
    padding: 0 1rem;
  }
  

}