.navbar-item{
    color: #0B132A;
}

.navbar-item img {
    width: 100%;
    height: 100;
}

.navbar-brand{
    align-items: center !important;
}

.navbar-item img {
    max-height: 100% !important;
}

.border-bottom{
    border-bottom: 1px solid #4f566524;
}

@media (max-width: 768px) {
    .buttons{
        display: flex;
        justify-content: center;
    }
}