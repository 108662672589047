.choose-your-plan .d-flex {
    gap: 6rem;
    padding: 2rem;
    justify-content: center;
    width: 100%;
}

.choose-your-plan table{
    text-align: initial;
    border-collapse: separate;
    border-spacing: 15px;
}

.choose-your-plan table td{
    margin: 0 1rem;
}


.choose-your-plan h3{
    font-size: 1.4rem;
}

.card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.choose-your-plan .card{
    border: 2px solid #DDDDDD;
    border-radius: 10px;
    width: inherit;
}

.choose-your-plan table {
    margin: auto;
}

@media (max-width: 1200px) {
    .choose-your-plan .d-flex {
        gap: 4rem;
    }
}

@media (max-width: 991px) {
    .choose-your-plan .d-flex {
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .choose-your-plan .d-flex {
        flex-direction: column;
        align-items: center;
    }

    .choose-your-plan .bullet {
        text-align: center;
    }
}