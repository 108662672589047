.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border-radius: 20px;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

iframe {
    margin: 2rem 0 0 !important;
    border-radius: 20px;
}

.iframeYT .low-embed-size {
    max-width: 70%;
    margin: auto;
}

@media (max-width: 575px) {
    .iframeYT .low-embed-size {
        max-width: 100%;
    }
}