.intro .column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.intro .columns{
    min-height: 600px;
}

.intro .title {
    text-align: initial;
    font-size: 3.6rem;
    width: 100%;
}

.intro .column div {
    width: 100%;
    text-align: initial;
}

.intro .button {
    padding: 1.5rem 3rem;
}

@media (max-width: 768px) {
    .intro{
        padding-top: 2rem;
    }

    .intro .title,
    .intro .column div {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .intro .title{
        font-size: 2.6rem;
    }
    
}