.blog {
    margin: 2rem auto !important;
}

.blog .column {
    text-align: start;
}

.blog .columns {
    gap: 2rem;
}

.blog img {
    width: 100%;
}

.blog h5 {
    color: #4F5665;
}

.blog h4 {
    margin: 0.6rem 0;
    color: #0B132A;
    font-weight: 700;
}


.blog a:hover img {
    transform: scale(1.5);
}

.blog img {
    transition: 0.3s;
    border-radius: 20px;
    display: flex;
}

.blog a:hover img {
    transform: scale(1.1);
}

.blog .img-blog {
    overflow: hidden;
    border-radius: 20px;
}

