.contact-us .columns .column:nth-child(2){
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
} 

.contact-us .columns .column:nth-child(3){
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: flex-start;
    text-align: left;
    margin: 4rem;
} 

.contact-us .control.has-icons-left .input {
    padding: calc(0.75em - 1px) !important; 
}

.contact-us .title-column{
    color: #3263B9;
    font-weight: bold;
}


@media (max-width: 1410px) {
    .contact-us .columns .column:nth-child(3) {
        margin: 1rem 0;
    }
}